<template>
  <div class="reset-password">
    <div class="img-form">
      <img :src="require(`@/assets/logo.png`)">
      <span>{{'Sales Center'}}</span>
    </div>
    <el-form
      class="login-form"
      label-position="top"
      ref="form"
      :model="form"
      label-width="80px"
    >
      <el-form-item prop="password">
        <el-input v-model="form.password" :type="inputType.password" placeholder="Please Input The New Password">
          <template #prefix>
            <i class="el-icon-lock"></i>
          </template>
          <i
            class="iconfont"
            :class="inputType.password === 'password' ? 'icon-eye' : 'icon-iov-noeye'"
            slot="suffix"
            @click="changeInputType('password')"
          >
          </i>
        </el-input>
      </el-form-item>
      <el-form-item prop="newPassword">
        <el-input :type="inputType.newPassword" v-model="form.newPassword" placeholder="Please Input The New Password Again">
          <template #prefix>
            <i class="el-icon-lock"></i>
          </template>
          <i
            class="iconfont"
            :class="inputType.newPassword === 'password' ? 'icon-eye' : 'icon-iov-noeye'"
            slot="suffix"
            @click="changeInputType('newPassword')"
          >
          </i>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          class="login-btn"
          type="primary"
          :loading="loading"
          @click="onSubmit"
        >Confirm</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { resetPassword } from '@/services/user'
export default {
  data () {
    return {
      imgurl: process.env.VUE_APP_NAME,
      loading: false,
      inputType: {
        password: 'password',
        newPassword: 'password'
      },
      form: {
        password: '',
        newPassword: ''
      },
      token: null
    }
  },
  created () {
    if (this.$route.query.token) {
      this.token = this.$route.query.token
    }
  },
  methods: {
    changeInputType (key) {
      if (this.inputType[key] === 'password') {
        this.inputType[key] = 'text'
      } else {
        this.inputType[key] = 'password'
      }
    },
    onSubmit () {
      if (!this.form.password && this.form.password !== 0) return
      if (this.form.password.trim() !== this.form.newPassword.trim()) {
        this.$message.error('The passwords entered twice are inconsistent')
        return
      }
      const data = {
        user: {
          password: this.form.password,
          token: this.token,
          password_confirmation: this.form.newPassword
        }
      }
      this.loading = true
      resetPassword(data).then(res => {
        this.$message.success('Operate Success!')
        setTimeout(() => {
          this.$router.push({
            name: 'login'
          })
        }, 2000)
      }).finally(() => {
        this.loading = false
      })
    }
  }

}
</script>
<style lang="scss">
  .reset-password{
    .el-icon-loading {
      font-size: 14px !important;
    }
    .language {
      margin-bottom: 10px;
      font-size: 12px;
      color: #909399;
      &--margin {
        margin: 0 5px;
      }
      &__text{
        cursor: pointer;
      }
      &--active {
        color: #40586F;
      }
    }
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient( 135deg, #d9ddd9 10%, #eaebec 100%);
    .img-form {
      width: 300px;
      padding: 30px 20px 20px 20px;
      text-align: center;
      background: rgba(255, 255 , 255, .8);
      border-radius: 5px 5px 0 0 ;
      display: flex;
      flex-direction: column;
      align-items: center;
      > span {
        font-size: 20px;
        margin-top: 8px;
        // font-weight: 500;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      > img {
        // padding: 20px;
        width: 150px;
      }
    }
    .form-btn-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .form-btn-box-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .login-form {
      width: 300px;
      background: rgba(255, 255 , 255, .8);
      padding: 20px;
      border-radius: 0 0 5px 5px;
    }
    .login-btn {
      width: 100%;
    }
  }
</style>
